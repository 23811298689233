import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131')
];

export const server_loads = [0,2,3,6,4,9];

export const dictionary = {
		"/(app)/(home)": [~21,[2]],
		"/(app)/(compilation)/360/[responseId]/add-provider": [~10,[3]],
		"/admin/dashboard": [~53,[6],[7]],
		"/admin/hashtags": [~54,[6],[7]],
		"/admin/hashtags/new-hashtag": [~56,[6],[7]],
		"/admin/hashtags/[tagId]": [~55,[6],[7]],
		"/admin/lives": [~57,[6],[7]],
		"/admin/lives/new-live": [~61,[6],[7]],
		"/admin/lives/[liveId]": [~58,[6],[7]],
		"/admin/lives/[liveId]/new-poll": [~60,[6],[7]],
		"/admin/lives/[liveId]/[pollId]": [~59,[6],[7]],
		"/admin/maspi": [~62,[6],[7]],
		"/admin/maspi/compare": [~63,[6],[7]],
		"/admin/maspi/evaluations": [~64,[6],[7]],
		"/admin/maspi/evaluations/new-evaluation": [~66,[6],[7]],
		"/admin/maspi/evaluations/[evaluationId]": [~65,[6],[7]],
		"/admin/maspi/reports": [~67,[6],[7]],
		"/admin/maspi/roles": [~68,[6],[7]],
		"/admin/maspi/roles/[roleId]": [~69,[6],[7]],
		"/admin/mentoring": [~70,[6],[7]],
		"/admin/mentoring/[trackId]": [~71,[6],[7]],
		"/admin/mentoring/[trackId]/edit": [~73,[6],[7]],
		"/admin/mentoring/[trackId]/new-instance": [~74,[6],[7]],
		"/admin/mentoring/[trackId]/[instanceId]": [~72,[6],[7]],
		"/admin/news": [~75,[6],[7]],
		"/admin/news/new-news": [~77,[6],[7]],
		"/admin/news/[newsId]": [~76,[6],[7]],
		"/admin/questionnaires": [~78,[6],[7]],
		"/admin/questionnaires/(survey-types)/survey-reports": [~79,[6],[7]],
		"/admin/questionnaires/(survey-types)/survey-reports/[surveyId]": [~80,[6],[7]],
		"/admin/questionnaires/(survey-types)/surveys": [~81,[6],[7]],
		"/admin/questionnaires/(survey-types)/surveys/new-survey": [~83,[6],[7]],
		"/admin/questionnaires/(survey-types)/surveys/[surveyId]": [~82,[6],[7]],
		"/admin/questionnaires/templates": [~84,[6],[7]],
		"/admin/questionnaires/templates/[questionnaireId]": [~85,[6],[7]],
		"/admin/settings": [~86,[6],[7]],
		"/admin/settings/billing": [~87,[6],[7]],
		"/admin/settings/organization-profile": [~88,[6],[7]],
		"/admin/users": [~89,[6],[7]],
		"/admin/users/[accountId]": [~90,[6],[7]],
		"/admin/users/[accountId]/manage-roles": [~91,[6],[7]],
		"/(app)/(feedback-actions)/ask-feedback": [17,[2,4]],
		"/(app)/(feedback-actions)/create-exchange": [18,[2,4]],
		"/docs/(home)": [92,[8]],
		"/docs/content/en/administration/billing": [94,[8]],
		"/docs/content/en/administration/interface": [95,[8]],
		"/docs/content/en/administration/live-events": [96,[8]],
		"/docs/content/en/administration/manage-surveys/overview": [97,[8]],
		"/docs/content/en/administration/manage-surveys/surveys": [98,[8]],
		"/docs/content/en/administration/manage-surveys/templates": [99,[8]],
		"/docs/content/en/administration/manage-users": [100,[8]],
		"/docs/content/en/administration/maspi/comparisons": [101,[8]],
		"/docs/content/en/administration/maspi/maspi-questionnaires": [102,[8]],
		"/docs/content/en/administration/maspi/maspi-roles": [103,[8]],
		"/docs/content/en/administration/maspi/overview": [104,[8]],
		"/docs/content/en/administration/mentoring": [105,[8]],
		"/docs/content/en/application/explore-reports": [106,[8]],
		"/docs/content/en/application/interface": [107,[8]],
		"/docs/content/en/application/manage-settings": [108,[8]],
		"/docs/content/en/support/common-questions": [109,[8]],
		"/docs/content/en/support/get-help": [110,[8]],
		"/docs/content/it/administration/billing": [111,[8]],
		"/docs/content/it/administration/interface": [112,[8]],
		"/docs/content/it/administration/live-events": [113,[8]],
		"/docs/content/it/administration/manage-surveys/overview": [114,[8]],
		"/docs/content/it/administration/manage-surveys/surveys": [115,[8]],
		"/docs/content/it/administration/manage-surveys/templates": [116,[8]],
		"/docs/content/it/administration/manage-users": [117,[8]],
		"/docs/content/it/administration/maspi/comparisons": [118,[8]],
		"/docs/content/it/administration/maspi/maspi-questionnaires": [119,[8]],
		"/docs/content/it/administration/maspi/maspi-roles": [120,[8]],
		"/docs/content/it/administration/maspi/overview": [121,[8]],
		"/docs/content/it/administration/mentoring": [122,[8]],
		"/docs/content/it/application/explore-reports": [123,[8]],
		"/docs/content/it/application/interface": [124,[8]],
		"/docs/content/it/application/manage-settings": [125,[8]],
		"/docs/content/it/support/common-questions": [126,[8]],
		"/docs/content/it/support/get-help": [127,[8]],
		"/docs/[...path]": [~93,[8]],
		"/errors": [~128],
		"/(app)/game": [~22,[2]],
		"/(app)/(feedback-actions)/give-feedback": [19,[2,4]],
		"/(app)/(feedback-actions)/give-feedback/[feedbackOriginId]": [~20],
		"/(auth)/join/[inviteId]": [~45,[5]],
		"/(app)/live/[liveId]": [~23,[2]],
		"/maintenance": [129],
		"/(app)/maspi": [~24,[2]],
		"/(app)/maspi/pills/[pillIndex]": [~25,[2]],
		"/(app)/maspi/self-reflection": [~26,[2]],
		"/(app)/maspi/self-reflection/report": [~27,[2]],
		"/(app)/news/[newsId]": [~28,[2]],
		"/no-capacity/[resource]": [~130],
		"/onboarding/user": [~131,[9]],
		"/(auth)/org-missing": [~46,[5]],
		"/(auth)/password-reset": [47,[5]],
		"/(app)/profile-info/[accountId]": [~29,[2]],
		"/(app)/(compilation)/questionnaire/[responseId]": [~11,[3]],
		"/(app)/(compilation)/questionnaire/[responseId]/submit": [~12,[3]],
		"/(app)/report": [~30,[2]],
		"/(app)/report/feedback/[exchangeId]": [~31,[2]],
		"/(app)/report/maspi/[responseId]": [~32,[2]],
		"/(app)/report/mentoring/[instanceId]": [~33,[2]],
		"/(app)/report/survey/[questionnaireId]/[responseId]": [~34,[2]],
		"/(app)/report/threesixty/[questionnaireId]/[responseId]": [~35,[2]],
		"/(app)/report/threesixty/[questionnaireId]/[responseId]/manage": [~36,[2]],
		"/(app)/settings": [37,[2]],
		"/(app)/settings/account": [~38,[2]],
		"/(app)/settings/privacy-policy": [~39,[2]],
		"/(app)/settings/privacy-policy/en": [40,[2]],
		"/(app)/settings/privacy-policy/it": [41,[2]],
		"/(app)/settings/profile": [~42,[2]],
		"/(auth)/signin": [~48,[5]],
		"/(auth)/signout": [~49,[5]],
		"/(auth)/signup": [~50,[5]],
		"/(app)/start-360": [~43,[2]],
		"/(app)/(compilation)/survey-completed": [15,[3]],
		"/(app)/(compilation)/surveys-provider/[receiverResponseId]": [~16,[3]],
		"/(app)/(compilation)/survey/[userRecipientId]": [~13,[3]],
		"/(app)/(compilation)/survey/[userRecipientId]/endcard": [~14,[3]],
		"/(app)/switch-organization": [~44,[2]],
		"/(auth)/tryhei": [51,[5]],
		"/(auth)/verify": [52,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';